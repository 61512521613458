<template>
  <div>
    <section class="section-top">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <h1>
              {{ env }}Dashboard
              <span v-on:click="refreshData" class="refresh-data"
                >(Refresh)</span
              >
            </h1>
            <h2>
              {{ dashboard.apartment }}
            </h2>
            <h3 class="pad-top">
              PayPoint Reference: {{ dashboard.paypoint_reference }}
              <span
                v-on:click="toggleBarcode"
                v-if="!showBarcode"
                class="toggle-barcode"
                >(Display Barcode)</span
              >
            </h3>

            <MessageBlock :messages="messages"></MessageBlock>

            <div v-show="showBarcode">
              <VueBarcode
                v-bind:value="dashboard.paypoint_reference"
                width="3.5"
                format="CODE128"
                class="barcode"
              >
                Unable to display Barcode
              </VueBarcode>
              <span
                v-if="showBarcode"
                v-on:click="toggleBarcode"
                class="toggle-barcode"
                >Hide Barcode</span
              >
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="row">
          <div class="col-sm-12" v-bind:class="headerClass">
            <div class="block block-icon">
              <div class="head">
                <span
                  class="tooltip-item"
                  tabindex="0"
                  v-b-popover.click.blur.top="
                    'This shows the a live total of credit (available to use) or debt (to pay back) on the account.'
                  "
                  title="PrePayment Balance"
                >
                  <font-awesome-icon icon="question-circle" />
                </span>
                <span class="heading">Balance</span>
              </div>
              <div class="content">
                <div v-bind:class="dashboard.balance_state">
                  <span v-if="dashboard.balance < 0">-</span>£{{
                    formatPrice(dashboard.balance)
                  }}
                </div>
                <div class="sub">
                  <a href="#" v-scroll-to="'#payment-element'"
                    >Top up balance</a
                  >
                </div>
              </div>
            </div>
          </div>

          <div v-if="dashboard.balance_debt_active" class="col-md-4 col-sm-12">
            <div class="block block-icon">
              <div class="head">
                <span
                  class="tooltip-item"
                  tabindex="0"
                  v-b-popover.click.blur.top="
                    'This shows the current debt balance for your account.'
                  "
                  title="Debt Balance"
                >
                  <font-awesome-icon icon="question-circle" />
                </span>
                <span class="heading">Debt Balance</span>
              </div>
              <div class="content">
                <div class="disconnect">
                  &pound;{{ formatPrice(dashboard.debt_balance) }}
                </div>
                <div class="sub-detail" v-if="dashboard.debt_percent > 0">
                  <strong>{{ dashboard.debt_percent }}&percnt;</strong> of each
                  top-up will be taken for debt recovery
                </div>
                <div class="sub-detail" v-else>
                  Your debt balance has now been cleared
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12" v-bind:class="headerClass">
            <div class="block block-icon">
              <div class="head">
                <span
                  class="tooltip-item"
                  tabindex="0"
                  v-b-popover.click.blur.top="
                    'This is the last payment made on the account to pay off any debt or to put towards future heat (credit).'
                  "
                  title="Last Payment"
                >
                  <font-awesome-icon icon="question-circle" />
                </span>
                <span class="heading">Last Payment</span>
              </div>
              <div class="content">
                <div v-if="dashboard.payments.length > 0">
                  <div>£{{ dashboard.payments[0].payment }}</div>
                  <div class="sub">
                    {{ dashboard.payments[0].friendly_since }}
                  </div>
                </div>
                <div v-if="dashboard.payments.length === 0">
                  <div>-</div>
                  <div class="sub">No payments</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VueBarcode from "vue-barcode";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import MessageBlock from "@/views/Components/Global/MessageBlock";

export default {
  name: "HeaderOverview",
  props: {
    dashboard: {
      type: Object,
      required: true
    }
  },
  components: {
    MessageBlock,
    VueBarcode,
    FontAwesomeIcon
  },
  computed: {
    env() {
      return process.env.VUE_APP_ENV;
    },
    messages() {
      return this.dashboard.messages;
    },
    headerClass() {
      if (this.dashboard.balance_debt_active) {
        return "col-md-4";
      }
      return "col-md-6";
    }
  },
  data() {
    return {
      showBarcode: false
    };
  },
  methods: {
    refreshData() {
      this.$emit("refresh");
    },
    toggleBarcode() {
      this.showBarcode = !this.showBarcode;
    },
    formatPrice(price) {
      if (price >= 0) {
        return price;
      }

      return parseFloat(Math.abs(price)).toFixed(2);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/scss/dashboard";

.barcode {
  width: 400px;
  height: auto;
}

.sub-detail {
  line-height: 1.8rem;
  padding-top: 10px;
  font-size: 0.8rem;
  color: #666;
}
</style>
