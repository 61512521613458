var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"section-top"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('h1',[_vm._v(" "+_vm._s(_vm.env)+"Dashboard "),_c('span',{staticClass:"refresh-data",on:{"click":_vm.refreshData}},[_vm._v("(Refresh)")])]),_c('h2',[_vm._v(" "+_vm._s(_vm.dashboard.apartment)+" ")]),_c('h3',{staticClass:"pad-top"},[_vm._v(" PayPoint Reference: "+_vm._s(_vm.dashboard.paypoint_reference)+" "),(!_vm.showBarcode)?_c('span',{staticClass:"toggle-barcode",on:{"click":_vm.toggleBarcode}},[_vm._v("(Display Barcode)")]):_vm._e()]),_c('MessageBlock',{attrs:{"messages":_vm.messages}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBarcode),expression:"showBarcode"}]},[_c('VueBarcode',{staticClass:"barcode",attrs:{"value":_vm.dashboard.paypoint_reference,"width":"3.5","format":"CODE128"}},[_vm._v(" Unable to display Barcode ")]),(_vm.showBarcode)?_c('span',{staticClass:"toggle-barcode",on:{"click":_vm.toggleBarcode}},[_vm._v("Hide Barcode")]):_vm._e()],1)],1)])])]),_c('section',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12",class:_vm.headerClass},[_c('div',{staticClass:"block block-icon"},[_c('div',{staticClass:"head"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.click.blur.top",value:(
                  'This shows the a live total of credit (available to use) or debt (to pay back) on the account.'
                ),expression:"\n                  'This shows the a live total of credit (available to use) or debt (to pay back) on the account.'\n                ",modifiers:{"click":true,"blur":true,"top":true}}],staticClass:"tooltip-item",attrs:{"tabindex":"0","title":"PrePayment Balance"}},[_c('font-awesome-icon',{attrs:{"icon":"question-circle"}})],1),_c('span',{staticClass:"heading"},[_vm._v("Balance")])]),_c('div',{staticClass:"content"},[_c('div',{class:_vm.dashboard.balance_state},[(_vm.dashboard.balance < 0)?_c('span',[_vm._v("-")]):_vm._e(),_vm._v("£"+_vm._s(_vm.formatPrice(_vm.dashboard.balance))+" ")]),_c('div',{staticClass:"sub"},[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#payment-element'),expression:"'#payment-element'"}],attrs:{"href":"#"}},[_vm._v("Top up balance")])])])])]),(_vm.dashboard.balance_debt_active)?_c('div',{staticClass:"col-md-4 col-sm-12"},[_c('div',{staticClass:"block block-icon"},[_c('div',{staticClass:"head"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.click.blur.top",value:(
                  'This shows the current debt balance for your account.'
                ),expression:"\n                  'This shows the current debt balance for your account.'\n                ",modifiers:{"click":true,"blur":true,"top":true}}],staticClass:"tooltip-item",attrs:{"tabindex":"0","title":"Debt Balance"}},[_c('font-awesome-icon',{attrs:{"icon":"question-circle"}})],1),_c('span',{staticClass:"heading"},[_vm._v("Debt Balance")])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"disconnect"},[_vm._v(" £"+_vm._s(_vm.formatPrice(_vm.dashboard.debt_balance))+" ")]),(_vm.dashboard.debt_percent > 0)?_c('div',{staticClass:"sub-detail"},[_c('strong',[_vm._v(_vm._s(_vm.dashboard.debt_percent)+"%")]),_vm._v(" of each top-up will be taken for debt recovery ")]):_c('div',{staticClass:"sub-detail"},[_vm._v(" Your debt balance has now been cleared ")])])])]):_vm._e(),_c('div',{staticClass:"col-sm-12",class:_vm.headerClass},[_c('div',{staticClass:"block block-icon"},[_c('div',{staticClass:"head"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.click.blur.top",value:(
                  'This is the last payment made on the account to pay off any debt or to put towards future heat (credit).'
                ),expression:"\n                  'This is the last payment made on the account to pay off any debt or to put towards future heat (credit).'\n                ",modifiers:{"click":true,"blur":true,"top":true}}],staticClass:"tooltip-item",attrs:{"tabindex":"0","title":"Last Payment"}},[_c('font-awesome-icon',{attrs:{"icon":"question-circle"}})],1),_c('span',{staticClass:"heading"},[_vm._v("Last Payment")])]),_c('div',{staticClass:"content"},[(_vm.dashboard.payments.length > 0)?_c('div',[_c('div',[_vm._v("£"+_vm._s(_vm.dashboard.payments[0].payment))]),_c('div',{staticClass:"sub"},[_vm._v(" "+_vm._s(_vm.dashboard.payments[0].friendly_since)+" ")])]):_vm._e(),(_vm.dashboard.payments.length === 0)?_c('div',[_c('div',[_vm._v("-")]),_c('div',{staticClass:"sub"},[_vm._v("No payments")])]):_vm._e()])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }